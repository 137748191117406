/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import { QuestionDto, QuestionFilter } from '../questions';
import { ApiResponse } from '../response';
import { QuestionFeedbackReportDto } from './useFeedback';
import { PagedResponse } from './usePageRequest';

export interface TheoryQuestionDto extends QuestionDto {
  lesson: number;
  question: string;
  answers: string[];
  reason?: string;
  bookmarked?: boolean;
  officialExam?: boolean;
  officialExamYear?: number;
  correctAnswerRate?: number;
  emptyAnswerRate?: number;
  wrongAnswerRate?: number;
  status: number;
}

export interface DetailedTheoryQuestionDto extends TheoryQuestionDto {
  author: string;
  creationDate: Date;
  trial: boolean;
  competitiveTest: boolean;
  competitiveTestMonth?: number;
  competitiveTestYear?: number;
}

export interface TheoryQuestionPostDto {
  lesson: number;
  question: string;
  aAnswer: string;
  bAnswer: string;
  cAnswer: string;
  correctAnswer: number;
  reason?: string;
  trial: boolean;
  officialExam: boolean;
  officialExamYear?: number;
  competitiveTest: boolean;
  competitiveTestMonth?: number;
  competitiveTestYear?: number;
}

export function useTheoryQuestion() {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function getTheoryQuestion(
    id: string,
  ): Promise<ApiResponse<TheoryQuestionDto>> {
    try {
      const response = await instance.get<TheoryQuestionDto>(
        `/theory/questions/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        sendMessage('¡Ups!', 'Parece que la pregunta no existe', 'danger');

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function getDetailedTheoryQuestion(
    id: string,
  ): Promise<ApiResponse<DetailedTheoryQuestionDto>> {
    try {
      const response = await instance.get<DetailedTheoryQuestionDto>(
        `/theory/questions/${id}/detail`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getTheoryQuestions(
    filter: QuestionFilter,
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<DetailedTheoryQuestionDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...filter,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<DetailedTheoryQuestionDto>
      >(`/theory/questions`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getRandomTheoryQuestions(
    lessons?: number[],
  ): Promise<ApiResponse<TheoryQuestionDto>> {
    const config = {
      params: {
        lessons,
      },
    };

    try {
      const response = await instance.get<TheoryQuestionDto>(
        `/theory/questions/random`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function markTheoryQuestion(
    question: TheoryQuestionDto,
  ): Promise<ApiResponse<any>> {
    const payload = {
      id: question.id,
      correctAnswer: question.correctAnswer,
      selectedAnswer: question.selectedAnswer,
    };

    try {
      const response = await instance.post(
        `/theory/questions/${question.id}/mark`,
        payload,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function addTheoryQuestion(
    question: TheoryQuestionPostDto,
  ): Promise<ApiResponse<any>> {
    const response = await instance.post(`/theory/questions`, question);

    sendMessage('¡Hecho!', 'Pregunta creada correctamente', 'success');

    return {
      success: response.status === 200,
    };
  }

  async function updateTheoryQuestion(
    questionId: string,
    question: TheoryQuestionPostDto,
  ): Promise<ApiResponse<any>> {
    const response = await instance.post(
      `/theory/questions/${questionId}`,
      question,
    );

    sendMessage('¡Hecho!', 'Pregunta actualizada correctamente', 'success');

    return {
      success: response.status === 200,
    };
  }

  async function deleteTheoryQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(`/theory/questions/${questionId}`);

      sendMessage(
        '¡Hecho!',
        'Pregunta marcada como obsoleta correctamente',
        'success',
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          '¡Ups!',
          'Parece que la pregunta no existe o no tienes permisos para eliminarla',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function bookmarkTheoryQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.put(
        `/theory/questions/${questionId}/bookmark`,
        {},
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function unbookmarkTheoryQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/theory/questions/${questionId}/bookmark`,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function reportTheoryQuestionError(
    questionId: string,
    description: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(
        `/theory/questions/${questionId}/errors`,
        {
          description,
        },
      );

      sendMessage('¡Error reportado!', 'Gracias compañerx 💙', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getTheoryQuestionReportedErrors(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<QuestionFeedbackReportDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<QuestionFeedbackReportDto>
      >(`/theory/questions/errors`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function deleteTheoryQuestionReportedError(
    questionId: string,
    feedbackId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/theory/questions/${questionId}/errors/${feedbackId}`,
      );

      sendMessage('¡Hecho!', 'Feedback eliminado correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage('¡Ups!', 'Parece que este feedback ya no existe', 'danger');

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function verifyTheoryQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.put(
        `/theory/questions/${questionId}/verify`,
        {},
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function rejectTheoryQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/theory/questions/${questionId}/verify`,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    getDetailedTheoryQuestion,
    getTheoryQuestions,
    getTheoryQuestion,
    getRandomTheoryQuestions,
    markTheoryQuestion,
    addTheoryQuestion,
    updateTheoryQuestion,
    deleteTheoryQuestion,
    bookmarkTheoryQuestion,
    unbookmarkTheoryQuestion,
    reportTheoryQuestionError,
    getTheoryQuestionReportedErrors,
    deleteTheoryQuestionReportedError,
    verifyTheoryQuestion,
    rejectTheoryQuestion,
  };
}
