import { useRef, useState } from 'react';

import {
  Button,
  ButtonGroup,
  CardPopup,
  FormFeedback,
  FormGroup,
  FormLabel,
  QuestionAnswer,
  SelectDropdown,
  SwitchCheck,
  Textarea,
  Tooltip,
} from '@sealfye/ui-components';
import { useIsMutating, useMutation, useQueries } from '@tanstack/react-query';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { IoTrashOutline } from 'react-icons/io5';
import * as Yup from 'yup';

import { useTheoryLesson } from '../../../../services/api/hooks/useTheoryLesson';
import {
  TheoryQuestionPostDto,
  useTheoryQuestion,
} from '../../../../services/api/hooks/useTheoryQuestion';
import { useEditor } from '../../../../state/editorStore';
import { useToasterActions } from '../../../../state/toasterStore';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { getMonthName } from '../../../../utils/date';
import { Loading } from '../../../core/Loading';
import { Editor } from '../../../shared/editor/Editor';

import styles from './TheoryQuestionEditor.module.scss';

type ContainerProps = BaseComponentProps & {
  id?: string;
  onClose: () => void;
};

function TheoryQuestionEditor({
  className,
  id = '00000000-0000-0000-0000-000000000000',
  onClose,
  testId = 'ui-question-editor',
}: ContainerProps) {
  const { sendMessage } = useToasterActions();

  const nodeRef = useRef<HTMLDivElement>(null);
  const nodeRefPreview = useRef<HTMLDivElement>(null);

  const editor = useEditor();

  const [showPreview, setShowPreview] = useState(false);

  const [payload, setPayload] = useState<TheoryQuestionPostDto>();

  const { getTheoryLessons } = useTheoryLesson();
  const {
    getDetailedTheoryQuestion,
    updateTheoryQuestion,
    addTheoryQuestion,
    deleteTheoryQuestion,
  } = useTheoryQuestion();

  const [lessonsQuery, payloadQuery] = useQueries({
    queries: [
      {
        queryKey: ['getTheoryLessons'],
        queryFn: () => getTheoryLessons(),
      },
      {
        queryKey: ['getDetailedTheoryQuestion', id],
        queryFn: () => getDetailedTheoryQuestion(id),
      },
    ],
  });

  const updateMutation = useMutation({
    mutationKey: ['updateTheoryQuestion', id],
    mutationFn: (payload: TheoryQuestionPostDto) =>
      updateTheoryQuestion(id, payload),
    onSuccess: () => {
      editor?.onEdit && editor.onEdit();
      nodeRef.current?.click();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      if (
        error.response.status === 400 &&
        error.response.data.error?.message === 'Competitive test full'
      ) {
        sendMessage(
          'Fallo de validación',
          'El simulacro para el mes seleccionado ya tiene 100 preguntas',
          'danger',
        );
      } else if (error.response.status === 400) {
        sendMessage(
          'Fallo de validación',
          'Parece que hay algún campo incorrecto',
          'danger',
        );
      }
    },
  });

  const addMutation = useMutation({
    mutationKey: ['addTheoryQuestion'],
    mutationFn: (payload: TheoryQuestionPostDto) => addTheoryQuestion(payload),
    onSuccess: () => {
      editor?.onEdit && editor.onEdit();
      nodeRef.current?.click();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      if (
        error.response.status === 400 &&
        error.response.data.error?.message === 'Competitive test full'
      ) {
        sendMessage(
          'Fallo de validación',
          'El simulacro para el mes seleccionado ya tiene 100 preguntas',
          'danger',
        );
      } else if (error.response.status === 400) {
        sendMessage(
          'Fallo de validación',
          'Parece que hay algún campo incorrecto',
          'danger',
        );
      }
    },
  });

  const isMutating = Boolean(
    useIsMutating({ mutationKey: ['addTheoryQuestion'] }),
  );

  const deleteMutation = useMutation({
    mutationKey: ['deleteTheoryQuestion'],
    mutationFn: (id: string) => deleteTheoryQuestion(id),
    onSuccess: () => {
      editor?.onDelete && editor.onDelete();
      nodeRef.current?.click();
    },
  });

  return (
    <>
      <CardPopup
        className={classnames(styles['main'], className)}
        refClose={nodeRef}
        onClose={onClose}
        closeOnOutsideClick={false}
        testId={testId}
      >
        <div className={styles['wrapper']}>
          {lessonsQuery.isLoading || payloadQuery.isLoading ? (
            <Loading />
          ) : (
            <Formik
              enableReinitialize
              validationSchema={Yup.object().shape({
                lesson: Yup.string().required(
                  'Especifica el tema de la pregunta.',
                ),
                question: Yup.string()
                  .min(10, 'La pregunta debe tener al menos 10 caracteres.')
                  .max(2000, 'La pregunta no debe superar los 2000 caracteres.')
                  .required('La pregunta no puede estar vacía.'),
                aAnswer: Yup.string()
                  .max(500, 'Las opciones no deben superar los 500 caracteres.')
                  .required('Las opciones no pueden estar vacías.'),
                bAnswer: Yup.string()
                  .max(500, 'Las opciones no deben superar los 500 caracteres.')
                  .required('Las opciones no pueden estar vacías.'),
                cAnswer: Yup.string()
                  .max(500, 'Las opciones no deben superar los 500 caracteres.')
                  .required('Las opciones no pueden estar vacías.'),
                correctAnswer: Yup.string().required(
                  'Especifica la respuesta correcta.',
                ),
                reason: Yup.string().max(
                  2500,
                  'La explicación no debe superar los 2500 caracteres.',
                ),
                officialExamYear: Yup.string().when('officialExam', {
                  is: true,
                  then: (schema) =>
                    schema.required('Especifica el año del examen'),
                }),
                competitiveTestMonth: Yup.string().when('competitiveTest', {
                  is: true,
                  then: (schema) =>
                    schema.required('Especifica el mes del simulacro'),
                }),
                competitiveTestYear: Yup.string().when('competitiveTest', {
                  is: true,
                  then: (schema) =>
                    schema.required('Especifica el año del simulacro'),
                }),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                try {
                  if (values.preview) {
                    setPayload({
                      ...payload,
                      lesson: +values.lesson,
                      question: values.question,
                      aAnswer: values.aAnswer,
                      bAnswer: values.bAnswer,
                      cAnswer: values.cAnswer,
                      correctAnswer: values.correctAnswer || 0,
                      reason: values.reason,
                      trial: values.trial,
                      officialExam: values.officialExam,
                      officialExamYear: values.officialExamYear,
                      competitiveTest: values.competitiveTest,
                      competitiveTestMonth: values.competitiveTestMonth,
                      competitiveTestYear: values.competitiveTestYear,
                    });

                    setShowPreview(true);
                  } else {
                    const payloadToSubmit = {
                      lesson:
                        values.officialExam && values.officialExamYear
                          ? +values.officialExamYear
                          : +values.lesson,
                      question: values.question,
                      aAnswer: values.aAnswer,
                      bAnswer: values.bAnswer,
                      cAnswer: values.cAnswer,
                      correctAnswer: values.correctAnswer ?? 0,
                      reason: values.reason,
                      trial: values.trial,
                      officialExam: values.officialExam,
                      officialExamYear:
                        values.officialExam && values.officialExamYear
                          ? +values.officialExamYear
                          : undefined,
                      competitiveTest: values.competitiveTest,
                      competitiveTestMonth:
                        values.competitiveTest && values.competitiveTestMonth
                          ? +values.competitiveTestMonth
                          : undefined,
                      competitiveTestYear:
                        values.competitiveTest && values.competitiveTestYear
                          ? +values.competitiveTestYear
                          : undefined,
                    };

                    if (id === '00000000-0000-0000-0000-000000000000') {
                      addMutation.mutate(payloadToSubmit);
                    } else {
                      updateMutation.mutate(payloadToSubmit);
                    }
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                  throw new Error(error);
                }

                setSubmitting(false);
              }}
              initialValues={{
                lesson: payloadQuery.data?.data?.lesson || 1,
                question: payloadQuery.data?.data?.question || '',
                aAnswer: payloadQuery.data?.data?.answers[0] || '',
                bAnswer: payloadQuery.data?.data?.answers[1] || '',
                cAnswer: payloadQuery.data?.data?.answers[2] || '',
                correctAnswer:
                  payloadQuery.data?.data?.correctAnswer || undefined,
                reason: payloadQuery.data?.data?.reason || '',
                trial: payloadQuery.data?.data?.trial || false,
                officialExam: payloadQuery.data?.data?.officialExam || false,
                officialExamYear:
                  payloadQuery.data?.data?.officialExamYear ||
                  new Date().getFullYear(),
                competitiveTest:
                  payloadQuery.data?.data?.competitiveTest || false,
                competitiveTestMonth:
                  payloadQuery.data?.data?.competitiveTestMonth ||
                  new Date().getMonth() + 2,
                competitiveTestYear:
                  payloadQuery.data?.data?.competitiveTestYear ||
                  new Date().getFullYear(),
                preview: false,
              }}
            >
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <Form
                  className={styles['form']}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className={styles['icon']}>{<span>✏️</span>}</div>
                  <FormGroup>
                    <FormLabel>Tema</FormLabel>
                    <SelectDropdown
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'lesson',
                            value,
                          },
                        });
                      }}
                      error={touched.lesson && !!errors.lesson}
                      options={
                        lessonsQuery.data?.data?.map((lesson) => ({
                          value: lesson.id,
                          label: lesson.id + ' - ' + lesson.title,
                          selected: values.lesson === lesson.id,
                        })) || []
                      }
                    />
                    <FormFeedback>
                      El temario de las oposiciones para Policía Nacional consta
                      45 temas divididos en tres bloques: ciencias jurídicas (1
                      al 26), ciencias sociales (27 al 36), materias
                      técnico-científicas (37 al 44).
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Pregunta</FormLabel>
                    <Textarea
                      id="question"
                      value={values.question}
                      rows={5}
                      onChange={handleChange}
                      error={touched.question && !!errors.question}
                    />
                    {touched.question && errors.question && (
                      <FormFeedback variant="danger">
                        {errors.question}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      Todas las preguntas procederán de los contenidos
                      especificados en el temario oficial de Policía Nacional
                      Escala Básica.
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción A</FormLabel>
                    <Textarea
                      id="aAnswer"
                      value={values.aAnswer}
                      rows={4}
                      onChange={handleChange}
                      error={touched.aAnswer && !!errors.aAnswer}
                    />
                    {touched.aAnswer && errors.aAnswer && (
                      <FormFeedback variant="danger">
                        {errors.aAnswer}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción B</FormLabel>
                    <Textarea
                      id="bAnswer"
                      value={values.bAnswer}
                      rows={4}
                      onChange={handleChange}
                      error={touched.bAnswer && !!errors.bAnswer}
                    />
                    {touched.bAnswer && errors.bAnswer && (
                      <FormFeedback variant="danger">
                        {errors.bAnswer}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción C</FormLabel>
                    <Textarea
                      id="cAnswer"
                      value={values.cAnswer}
                      rows={4}
                      onChange={handleChange}
                      error={touched.cAnswer && !!errors.cAnswer}
                    />
                    {touched.cAnswer && errors.cAnswer && (
                      <FormFeedback variant="danger">
                        {errors.cAnswer}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción correcta</FormLabel>
                    <ButtonGroup
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'correctAnswer',
                            value,
                          },
                        });
                      }}
                      error={touched.correctAnswer && !!errors.correctAnswer}
                      options={[
                        {
                          value: 1,
                          label: 'A',
                          selected: values.correctAnswer === 1,
                        },
                        {
                          value: 2,
                          label: 'B',
                          selected: values.correctAnswer === 2,
                        },
                        {
                          value: 3,
                          label: 'C',
                          selected: values.correctAnswer === 3,
                        },
                      ]}
                    />
                    {touched.correctAnswer && errors.correctAnswer && (
                      <FormFeedback variant="danger">
                        {errors.correctAnswer}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      Cada pregunta consta de 3 respuestas alternativas, siendo
                      solo 1 la correcta.
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Retroalimentación</FormLabel>
                    <Editor
                      content={values.reason}
                      onContentChange={(value) => {
                        handleChange({
                          target: {
                            name: 'reason',
                            value,
                          },
                        });
                      }}
                      error={touched.reason && !!errors.reason}
                    />
                    {touched.reason && errors.reason && (
                      <FormFeedback variant="danger">
                        {errors.reason}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      Cada explicación ayuda a entender y memorizar la
                      respuesta, siempre que puedas elabora una buena
                      descripción de los contenidos.
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Simulacro mensual</FormLabel>
                    <SwitchCheck
                      label="Pregunta de simulacro mensual"
                      checked={values.competitiveTest}
                      onChange={() => {
                        handleChange({
                          target: {
                            name: 'competitiveTest',
                            value: !values.competitiveTest,
                          },
                        });
                      }}
                    />
                    <FormFeedback>
                      Marca esta opción si es una pregunta para un simulacro
                      mensual.
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <SelectDropdown
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'competitiveTestMonth',
                            value,
                          },
                        });
                      }}
                      disabled={values.competitiveTest === false}
                      options={Array.from(Array(12).keys()).map((value) => ({
                        value: value + 1,
                        label: getMonthName(value),
                        selected: value + 1 === values.competitiveTestMonth,
                      }))}
                    />
                    <FormFeedback>
                      Selecciona el mes del simulacro.
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <SelectDropdown
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'competitiveTestYear',
                            value,
                          },
                        });
                      }}
                      disabled={values.competitiveTest === false}
                      options={Array.from(Array(100).keys()).map((value) => ({
                        value: value + 2000,
                        label: (value + 2000).toString(),
                        selected: value + 2000 === values.competitiveTestYear,
                      }))}
                    />
                    <FormFeedback>
                      Selecciona el año del simulacro.
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Pregunta oficial de examen</FormLabel>
                    <SwitchCheck
                      label="Pregunta oficial de examen Escala Básica"
                      checked={values.officialExam}
                      onChange={() => {
                        handleChange({
                          target: {
                            name: 'officialExam',
                            value: !values.officialExam,
                          },
                        });
                      }}
                    />
                    <FormFeedback>
                      Marca esta opción si es una pregunta oficial de un examen
                      de teoría para Escala Básica.
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <SelectDropdown
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'officialExamYear',
                            value,
                          },
                        });
                      }}
                      disabled={values.officialExam === false}
                      options={Array.from(Array(100).keys()).map((value) => ({
                        value: value + 2000,
                        label: (value + 2000).toString(),
                        selected: value + 2000 === values.officialExamYear,
                      }))}
                    />
                    <FormFeedback>Selecciona el año del examen.</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Trial</FormLabel>
                    <SwitchCheck
                      label="Pregunta para usuarios con licencia gratuita"
                      checked={values.trial}
                      onChange={() => {
                        handleChange({
                          target: {
                            name: 'trial',
                            value: !values.trial,
                          },
                        });
                      }}
                    />
                    <FormFeedback>
                      Marca esta opción si quieres que esta pregunta sea visible
                      por usuarios con licencia gratuita.
                    </FormFeedback>
                  </FormGroup>

                  <div className={styles['footer']}>
                    {id !== '00000000-0000-0000-0000-000000000000' && (
                      <Tooltip
                        icon={
                          <IoTrashOutline className={styles['remove-icon']} />
                        }
                        placement="top"
                        behavior="click"
                      >
                        <div className={styles['tooltip-payload']}>
                          <span>¿Estás segurx?</span>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              deleteMutation.mutate(id);
                            }}
                          >
                            Eliminar
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                    <Button
                      className={styles['button']}
                      loading={isSubmitting || isMutating}
                      disabled={isSubmitting || isMutating}
                      variant="outline-primary"
                      onClick={() => {
                        values.preview = true;
                        handleSubmit();
                      }}
                    >
                      Previsualizar
                    </Button>
                    <Button
                      className={styles['button']}
                      loading={isSubmitting || isMutating}
                      disabled={isSubmitting || isMutating}
                      variant="primary"
                      onClick={() => {
                        values.preview = false;
                        handleSubmit();
                      }}
                    >
                      Enviar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </CardPopup>
      {showPreview && payload && (
        <CardPopup
          className={styles['card-popup']}
          onClose={() => setShowPreview(false)}
          refClose={nodeRefPreview}
          closeable={false}
        >
          <QuestionAnswer
            question={{
              id: '',
              text: payload.question,
              options: [payload.aAnswer, payload.bAnswer, payload.cAnswer],
              subject:
                payload.lesson > 2000
                  ? `Examen oficial ${payload.lesson}`
                  : `Tema ${payload.lesson}`,
              selectedOption: payload.correctAnswer,
              correctOption: payload.correctAnswer ?? 0,
              reason: payload.reason,
            }}
            background="main"
            onClose={() => {
              nodeRefPreview.current?.click();
            }}
          />
        </CardPopup>
      )}
    </>
  );
}

export { TheoryQuestionEditor };
